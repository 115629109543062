import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { OrderSummary, OrderCategorySummary, RevenueSummary, Period } from './dashboard.model';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    ///////////////////////////////////////////////////////////////
    ////  Methods for order summary
    ///////////////////////////////////////////////////////////////

    private _orderSummary: BehaviorSubject<OrderSummary | null> = new BehaviorSubject(null);
    orderSummaryErrorMessage: string = "Something went wrong ";

    get orderSummary$(): Observable<OrderSummary> {
        return this._orderSummary.asObservable();
    }

    getOrderSummary(period: Period): Observable<OrderSummary> {
        let parameters = new HttpParams().set("period", period);
        return this._httpClient.get<OrderSummary>(`${BASE_URL}administration/summary/orders/`, { params: parameters }).pipe(
            tap((response) => {
                this._orderSummary.next(response);
            })
        );
    }

    ///////////////////////////////////////////////////////////////
    ////  Methods for order category summary
    ///////////////////////////////////////////////////////////////

    private _orderCategorySummary: BehaviorSubject<OrderCategorySummary[] | null> = new BehaviorSubject(null);
    orderCategoryErrorMessage: string = "Something went wrong ";

    get orderCategorySummary$(): Observable<OrderCategorySummary[]> {
        return this._orderCategorySummary.asObservable();
    }

    getOrderCategorySummary(period: Period): Observable<OrderCategorySummary[]> {
        let parameters = new HttpParams().set("period", period);
        return this._httpClient.get<OrderCategorySummary[]>(`${BASE_URL}administration/summary/orders/categories/`, { params: parameters }).pipe(
            tap((response) => {
                let total = 0
                response.forEach(element => {
                    total = total + element.count
                });
                response.forEach(element => {
                    element.percentage = (element.count / total) * 100
                });
                this._orderCategorySummary.next(response);
            })
        );
    }
    ///////////////////////////////////////////////////////////////
    ////  Methods for revenue summary
    ///////////////////////////////////////////////////////////////

    private _revenueSummary: BehaviorSubject<RevenueSummary | null> = new BehaviorSubject(null);
    revenueErrorMessage: string = "Something went wrong ";

    get revenueSummary$(): Observable<RevenueSummary> {
        return this._revenueSummary.asObservable();
    }

    getRevenueSummary(period: Period): Observable<RevenueSummary> {
        let parameters = new HttpParams().set("period", period);
        return this._httpClient.get<RevenueSummary>(`${BASE_URL}administration/summary/revenue/`, { params: parameters }).pipe(
            tap((response) => {
                this._revenueSummary.next(response);
            })
        );
    }

}
