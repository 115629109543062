import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { DashboardService } from 'app/core/dashboard/dashboard.service';
import { InitialData } from './report.types';
import { catchError, map } from 'rxjs/operators';
import { Period } from 'app/core/dashboard/dashboard.model';
import { OrderService } from 'app/core/order/order.service';
import { CategoryService } from 'app/core/category/category.service';

@Injectable({
    providedIn: 'root'
})
export class ReportDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _categoryService: CategoryService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._categoryService.getCategories()
        .pipe(
            map((categories) => ({
                categories
            })
            ),
            catchError(err => {
                this._categoryService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                return of(new DefaultResponse().categories);
            })
        );
    }

}

class DefaultResponse implements InitialData {
    categories: any = [];
}
