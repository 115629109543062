import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import {tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { ColorConfig } from './color.model';

@Injectable({
    providedIn: 'root'
})
export class ColorService {

    private _colors: BehaviorSubject<ColorConfig[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for colors
     */
    get colors$(): Observable<ColorConfig[]> {
        return this._colors.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get color list 
     */
    getColors(): Observable<ColorConfig[]> {
        return this._httpClient.get<ColorConfig[]>(`${BASE_URL}administration/colors/`).pipe(
            tap((response) => {
                this._colors.next(response ? response : []);
            })
        );
    }

    /**
     * Delete color
     * @param colorId
     */
    deleteColor(colorId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/color/${colorId}/`).pipe(
            tap((res: any) => {
                this._colors.next(this._colors.value.filter(color => color.id !== colorId));
            }, err => of([]))
        )
    }


    createColor(credentials: { color_code: string; name: string; }): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/color/`, credentials);
    }

    updateColor(credentials: { color_code: string; name: string; }, colorId: number): Observable<any> {        
        return this._httpClient.put(`${BASE_URL}administration/color/${colorId}/`, credentials).pipe(
            tap((res: any) => {
                this._colors.value.forEach(color=>{
                    if(color.id === colorId)
                    {
                        color.color_code = credentials.color_code;
                        color.name = credentials.name
                    }
                })
                this._colors.next(this._colors.value)
            }, err => of([]))
        )
    }

}
