import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProductService } from 'app/core/product/product.service';
import { InitialData } from 'app/modules/admin/manage-product/product.types';

@Injectable({
    providedIn: 'root'
})
export class ProductDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _productService: ProductService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot): Observable<InitialData> {
        console.log(route.url[0].path == "accessory");

        var request = (route.url[0].path == "accessory") ? this._productService.getAccessories() : this._productService.getProducts()
        return request
            .pipe(
                map((products) => ({
                    products
                })
                ),
                catchError(err => {
                    this._productService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().products);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    products: any = [];
}