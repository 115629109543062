import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ColorService } from 'app/core/color/color.service';
import { InitialData } from 'app/modules/admin/manage-color/color.types';

@Injectable({
    providedIn: 'root'
})
export class ColorDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _colorService: ColorService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._colorService.getColors()
            .pipe(
                map((colors) => ({
                    colors
                })
                ),
                catchError(err => {
                    this._colorService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().colors);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    colors: any = [];
}