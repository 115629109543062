export interface OrderSummary {
    orders: number;
    new_orders: number;
    total_users: number;
    new_users: number;
    total_revenue: number;
    total_subscriptions: number;
    new_subscriptions: number;
}

export interface OrderCategorySummary {
    category: number;
    count: number;
    percentage:number;
}

export interface RevenueSummary{
    print_order_revenue : RevenueDetail[];
    digital_subscriptions_revenue : RevenueDetail[];
}

export interface RevenueDetail {
    month: number;
    year: number;
    total: number;
}

export enum Period {
    all="all",
    month="month",
    year="year",
    day="day"
}
