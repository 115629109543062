import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Franchise, FranchiseCategory, FranchiseCategorydetails } from './franchise.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class FranchiseService {

    private _franchises: BehaviorSubject<Franchise[] | null> = new BehaviorSubject(null);
    private _franchiseAll: BehaviorSubject<Franchise[] | null> = new BehaviorSubject(null);
    private _franchiseCategories: BehaviorSubject<FranchiseCategory[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for franchises
     */
    get franchises$(): Observable<Franchise[]> {
        return this._franchises.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get franchise list 
     */
     getfranchises(categoryId: number): Observable<Franchise[]> {
        return this._httpClient.get<Franchise[]>(`${BASE_URL}administration/categories/franchise-category/${categoryId}/franchises/`).pipe(
            tap((response) => {
                this._franchises.next(response ? response : []);
            })
        );
    }

    /**
     * Get all franchise list 
     */
    getAllFranchises(): Observable<Franchise[]> {
        return this._httpClient.get<Franchise[]>(`${BASE_URL}administration/categories/franchises/all/`).pipe(
            tap((response) => {
                this._franchiseAll.next(response ? response : null);
            })
        );
    }

    /**
    * Create franchise
    *
    * @param string 
    */
    createFranchise(categoryId, franchise: { name: string }): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/categories/franchise-category/${categoryId}/franchises/`, franchise);
    }

    /**
     * Delete franchise
     */
    deleteFranchise(franchiseId: number): Observable<any> {
        return this._httpClient.delete<any>(`${BASE_URL}administration/categories/franchises/${franchiseId}/`).pipe(
            tap((response) => {
                this._franchises.next(this._franchises.value.filter(franchise => franchise.id !== franchiseId));
            })
        );
    }


    updateFranchise(franchiseId: number, franchise: { name: string }): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/categories/franchises/${franchiseId}/`, franchise).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                if (response?.id != null) {
                    let cat = this._franchises.value.find(franchise => franchise.id == franchiseId);
                    cat.name = franchise.name;
                    this._franchises.next(this._franchises.value);
                }
            }, err => {
            })
        );
    }

    //////////////////////////////////////////////////////////////////////////////////////////
    /**
     * Getter for franchise categories
     */
    get franchiseCategories$(): Observable<FranchiseCategory[]> {
        return this._franchiseCategories.asObservable();
    }

    /**
     * Get franchise category list 
     */
    getFranchiseCategories(): Observable<FranchiseCategory[]> {
        return this._httpClient.get<FranchiseCategory[]>(`${BASE_URL}administration/categories/franchise-categories/`).pipe(
            tap((response) => {
                this._franchiseCategories.next(response ? response : []);
            })
        );
    }


    createFranchiseCategory(franchise: { name: string }): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/categories/franchise-categories/`, franchise);
    }

    updateFranchiseCategory(categoryId: number, franchiseCategory: { name: string }): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/categories/franchise-category/${categoryId}/`, franchiseCategory).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                if (response?.id != null) {
                    let cat = this._franchiseCategories.value.find(franchise => franchise.id == categoryId);
                    cat.name = franchiseCategory.name;
                    this._franchiseCategories.next(this._franchiseCategories.value);
                }
            })
        );
    }

    /**
     * Delete franchise category
     */
    deleteFranchiseCategory(categoryId: number): Observable<any> {
        return this._httpClient.delete<any>(`${BASE_URL}administration/categories/franchise-category/${categoryId}/`).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this._franchiseCategories.next(this._franchiseCategories.value.filter(franchise => franchise.id !== categoryId));
            })
        );
    }

}
