import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrderService } from 'app/core/order/order.service';
import { InitialData } from 'app/modules/admin/manage-order-details/order-detail.types';

@Injectable({
    providedIn: 'root'
})
export class OrderDetailDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _orderService: OrderService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._orderService.getOrderDetail(route.params['id'])
        .pipe(
            map((orderDetail) => ({
                orderDetail
            })
            ),
            catchError(err => {
                this._orderService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                return of(new DefaultResponse().orderDetail);
            })
        );
        
    }

}
class DefaultResponse implements InitialData {
    orderDetail: any = [];
}