import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { FAQ, FAQCategory, FAQCategoryList, FAQList, FAQVideo, FAQVideoList } from './faq.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class FAQService {

    private _categories: BehaviorSubject<FAQCategory[] | null> = new BehaviorSubject(null);
    private _video: BehaviorSubject<FAQVideo[] | null> = new BehaviorSubject(null);
    private _faqs: BehaviorSubject<FAQ[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";
    categoryPagination: Pagination;
    videoPagination: Pagination;
    faqPagination: Pagination;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for categories
     */
    get categories$(): Observable<FAQCategory[]> {
        return this._categories.asObservable();
    }

    /**
     * Getter for faq list
     */
    get faqs$(): Observable<FAQ[]> {
        return this._faqs.asObservable();
    }

    /**
     * Getter for faq video
     */
    get videos$(): Observable<FAQVideo[]> {
        return this._video.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods for faq categories
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get category list 
     */
    getCategories(): Observable<FAQCategoryList> {
        return this._httpClient.get<FAQCategoryList>(`${BASE_URL}administration/faq_category/`).pipe(
            tap((response) => {
                this._categories.next(response?.data ?? []);
                this.categoryPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };

            })
        );
    }

    /**
     * Delete category
     * @param categoryId
     */
    deleteCategory(categoryId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/faq_category/${categoryId}/`).pipe(
            tap((res: any) => {
                this._categories.next(this._categories.value.filter(category => category.id !== categoryId));
            }, err => of([]))
        )
    }

    createCategory(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/faq_category/`, credentials);
    }

    updateCategory(credentials: FormData, categoryId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/faq_category/${categoryId}/`, credentials).pipe(
            tap((res: any) => {
                this._categories.value.forEach(category => {
                    if (category.id === categoryId) {
                        category.title = res.title
                        category.description = res.description
                        category.image = res.image
                    }
                })
                this._categories.next(this._categories.value)
            }, err => of([]))
        )
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods for faq
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get faq list 
     */
    getFaqs(categoryId): Observable<FAQList> {
        return this._httpClient.get<FAQList>(`${BASE_URL}administration/faq_category/${categoryId}/faq/`).pipe(
            tap((response) => {
                this.faqPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._faqs.next(response?.data ?? []);
            })
        );
    }

    deleteFaq(categoryId, faqId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/faq_category/${categoryId}/faq/${faqId}/`).pipe(
            tap((res: any) => {
                this._faqs.next(this._faqs.value.filter(faq => faq.id !== faqId));
            }, err => of([]))
        )
    }

    createFaq(categoryId, credentials: { question: string; answer: string; category_id: number }, faqId: number): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/faq_category/${categoryId}/faq/`, { question: credentials.question, answer: credentials.answer, category_id: faqId });
    }

    updateFaq(categoryId, credentials: { question: string; answer: string; }, faqId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/faq_category/${categoryId}/faq/${faqId}/`, credentials).pipe(
            tap((res: any) => {
                this._faqs.value.forEach(faq => {
                    if (faq.id === faqId) {
                        faq.question = credentials.question
                        faq.answer = credentials.answer
                    }
                })
                this._categories.next(this._categories.value)
            }, err => of([]))
        )
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods for faq videos
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get category list 
     */
    getVideos(): Observable<FAQVideoList> {
        return this._httpClient.get<FAQVideoList>(`${BASE_URL}administration/help_video/`).pipe(
            tap((response) => {
                this._video.next(response?.data ?? []);
                this.videoPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
            })
        );
    }

    /**
     * Delete category
     * @param videoId
     */
    deleteVideo(videoId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/help_video/${videoId}/`).pipe(
            tap((res: any) => {
                this._video.next(this._video.value.filter(video => video.id !== videoId));
            }, err => of([]))
        )
    }

    createVideo(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/help_video/`, credentials);
    }

    updateVideo(credentials: FormData, videoId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/help_video/${videoId}/`, credentials).pipe(
            tap((res: any) => {
                this._video.value.forEach(video => {
                    if (video.id === videoId) {
                        video.title = res.title
                        video.faq_category = res.faq_category
                        video.faq_category_name = res.faq_category_name
                        video.description = res.description
                    }
                })
                this._video.next(this._video.value)
            }, err => of([]))
        )
    }

}
