import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmailSignatureService } from 'app/core/email-signature/email-signature.service';
import { InitialData } from 'app/modules/admin/manage-email-signature/email-signature.types';

@Injectable({
    providedIn: 'root'
})
export class EmailSignatureDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _adminService: EmailSignatureService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._adminService.getEmailSignaturesInitial()
            .pipe(
                map((signatures) => ({
                    signatures
                })
                ),
                catchError(err => {
                    this._adminService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().signatures);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    signatures: any = [];
}