import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { OrderCategorySummary, OrderSummary, Period, RevenueDetail, RevenueSummary } from 'app/core/dashboard/dashboard.model';
import { DashboardService } from 'app/core/dashboard/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
  ApexYAxis,
  NgApexchartsModule
} from "ng-apexcharts";
import { OrderService } from 'app/core/order/order.service';
import { Order } from 'app/core/order/order.model';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

export type ChartOptions = {
  yaxis: ApexYAxis;
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
  standalone: true,
  imports: [
    FormsModule, ReactiveFormsModule, MatButtonModule, MatFormFieldModule,
    MatButtonToggleModule, NgApexchartsModule, MatInputModule, MatProgressBarModule,
    MatRippleModule, MatMenuModule, MatIconModule, MatTableModule,
    MatSortModule, CommonModule, MatProgressSpinnerModule, RouterModule
  ]
})
export class DashboardComponent implements OnInit {

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _dashboardService: DashboardService,
    private _orderService: OrderService,
    private _toaster: ToastrService) { }

  public chartOptions: Partial<ChartOptions>;

  isLoadingEmitter$ = new BehaviorSubject<boolean>(false);

  showLoading(showLoading: boolean) {
    this.isLoadingEmitter$.next(showLoading);
  }

  orderSummary: OrderSummary
  orderCategorySummary: OrderCategorySummary[]
  printRevenueSummary: RevenueDetail[]
  digitalRevenueSummary: RevenueDetail[]

  orders$: Observable<Order[]>;
  displayedColumns: string[] = ['slNo', 'id', 'reference_id', 'placed_by_name', 'total_price', 'added_at', 'status', 'actions'];
  orderCount = 0
  ngOnInit(): void {

    // Subscribe to orderList 
    this.orders$ = this._orderService.orders$;
    this._orderService.orders$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((summary: Order[]) => {
        this.orderCount = summary.length
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Subscribe to orderSummary changes
    this._dashboardService.orderSummary$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((summary: OrderSummary) => {
        this.orderSummary = summary;
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Subscribe to user changes
    this._dashboardService.orderCategorySummary$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((summary: OrderCategorySummary[]) => {
        this.orderCategorySummary = summary;
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Subscribe to user changes
    this._dashboardService.revenueSummary$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((summary: RevenueSummary) => {
        this.printRevenueSummary = summary.print_order_revenue;
        this.digitalRevenueSummary = summary.digital_subscriptions_revenue;
        this.setRevenueChart();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

  }

  hasRevenueData = false;
  setRevenueChart() {
    let xAxisValues = []
    let yAxisValues = []
    this.printRevenueSummary.forEach(element => {
      xAxisValues.push(this.getMonthFromNumber(element.month) + "\n" + element.year)
      yAxisValues.push(element.total)
    });
    this.chartOptions = {
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      },
      series: [
        {
          name: "Orders",
          data: yAxisValues
        }
      ],
      chart: {
        height: 250,
        type: "line",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        colors: ["#3554d5"],
        width: 2
      },
      grid: {
        row: {
          colors: ["transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: xAxisValues
      }

    };
    this.hasRevenueData = this.printRevenueSummary.length > 0;
  }

  getMonthFromNumber(monthNumber: number) {
    switch (monthNumber) {
      case 1: return "Jan";
      case 2: return "Feb";
      case 3: return "Mar";
      case 4: return "Apr";
      case 5: return "May";
      case 6: return "Jun";
      case 7: return "Jul";
      case 8: return "Aug";
      case 9: return "Sep";
      case 10: return "Oct";
      case 11: return "Nov";
      case 12: return "Dec";
    }
  }

  /**
  * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  overviewFilter = "All Time"
  onChangeOverviewPeriod(period: string, displayValue: string) {
    this.overviewFilter = displayValue;
    var filterPeriod = Period[period];
    this._dashboardService.getOrderSummary(filterPeriod).pipe(
      finalize(() => {
        this.showLoading(false);
      })).subscribe((res) => { },
        (err) => {
          this._toaster.error(err.error.message ? err.error.message : "Something went wrong. Please try again", "Error");
        });
  }

  orderCategoryFilter = "All Time"
  onChangeOrdersCategoryPeriod(period: string, displayValue: string) {
    this.orderCategoryFilter = displayValue;
    var filterPeriod = Period[period];
    this._dashboardService.getOrderCategorySummary(filterPeriod).pipe(
      finalize(() => {
        this.showLoading(false);
      })).subscribe((res) => { },
        (err) => {
          this._toaster.error(err.error.message ? err.error.message : "Something went wrong. Please try again", "Error");
        });
  }

  revenueFilter = "All Time"
  onChangeRevenuePeriod(period: string, displayValue: string) {
    this.revenueFilter = displayValue;
    var filterPeriod = Period[period];
    this._dashboardService.getRevenueSummary(filterPeriod).pipe(
      finalize(() => {
        this.showLoading(false);
      })).subscribe((res) => { },
        (err) => {
          this._toaster.error(err.error.message ? err.error.message : "Something went wrong. Please try again", "Error");
        });
  }


}
