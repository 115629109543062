import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { EmailSignature, EmailSignatureResponse } from './email-signature.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class EmailSignatureService {

    private _emailSignatures: BehaviorSubject<EmailSignature[] | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for emailSignatures
     */
    get emailSignatures$(): Observable<EmailSignature[]> {
        return this._emailSignatures.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get emailSignature list
     */
    getEmailSignaturesInitial(): Observable<EmailSignatureResponse> {
        return this.getEmailSignatures(null, null, null, null, null);
    }

    /**
     * Get emailSignature list with search and sort
     */
    getEmailSignatures(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<EmailSignatureResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<EmailSignatureResponse>(`${BASE_URL}administration/email_signature/`, {
            params: {
                page: page ? ++page : 1,
                sort: sort ? sort : '',
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._emailSignatures.next(response.data ?? []);
            })
        );
    }

    /**
     * Activate or suspend an emailSignature
     * @param isActive 'true' to activate 'false' to suspend
     * @param emailSignatureId
     */
    changeEmailSignatureStatus(isActive: boolean, emailSignatureId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/email_signature/${emailSignatureId}/`, {
            is_active: isActive
        }).pipe(
            tap((res: any) => {
                let newArrayOfEmailSignatures = this._emailSignatures.value;
                newArrayOfEmailSignatures.forEach(element => {
                    if (element.id == emailSignatureId)
                        element.is_active = res?.is_active 
                });
                this._emailSignatures.next(newArrayOfEmailSignatures);
                return of(res);
            }, err => of([]))
        )
    }

    /**
    * Create emailSignature
    *
    * @param EmailSignatureDetails
    */
    createEmailSignature(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/email_signature/`, credentials);
    }

    /**
    * Update emailSignature
    *
    * @param EmailSignatureDetails
    */
    updateEmailSignature(credentials, signatureId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/email_signature/${signatureId}/`, credentials);
    }

}
