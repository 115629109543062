import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './tax.types';
import { TaxService } from 'app/core/tax/tax.service';

@Injectable({
    providedIn: 'root'
})
export class TaxDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _taxService: TaxService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._taxService.getTaxes(0,10,'')
        .pipe(
            map((taxes) => ({
                taxes
            })
            ),
            catchError(err => {
                console.log(err);
                
                this._taxService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                return of(new DefaultResponse().taxes);
            })
        );
        
    }

}
class DefaultResponse implements InitialData {
    taxes: any = [];
}