import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CategoryService } from 'app/core/category/category.service';
import { InitialData } from 'app/modules/admin/manage-franchise/franchise.types';
import { FranchiseService } from 'app/core/franchise/franchise.service';

@Injectable({
    providedIn: 'root'
})
export class FranchiseDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _franchiseService: FranchiseService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._franchiseService.getfranchises(route.params['id'])
        .pipe(
            map((franchises) => ({
                franchises
            })
            ),
            catchError(err => {
                this._franchiseService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                return of(new DefaultResponse().franchises);
            })
        );
        
    }

}
class DefaultResponse implements InitialData {
    franchises: any = [];
}