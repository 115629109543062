import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { PromocodeResponse, Promocode } from './promocode.model';
import { Pagination } from 'app/shared/pagination.type';
import { DatePipe, formatDate } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class PromocodeService {

    private _promocodes: BehaviorSubject<Promocode[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for promocodes
     */
    get promocodes$(): Observable<Promocode[]> {
        return this._promocodes.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get promocode list 
     */
    getPromocodes(): Observable<PromocodeResponse> {
        return this._httpClient.get<PromocodeResponse>(`${BASE_URL}administration/promocodes/list/`).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this._promocodes.next(response.promo_codes ? response.promo_codes : []);
            })
        );
    }

    /**
     * Activate or deactivate
     * @param isActive 'true' to activate 'false' to deactivate
     * @param promocodeId
     */
    changePromocodeStatus(isActive: boolean, promocode: Promocode): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/promocodes/${promocode.id}/`, {
            code: promocode.code,
            product_id: promocode.id,
            fixed_price_deduction: promocode.fixed_price_deduction,
            percentage_deduction: promocode.percentage_deduction,
            deduction_limit: promocode.deduction_limit,
            valid_till: promocode.valid_till,
            is_active: isActive
        }).pipe(
            tap((res: any) => {
                promocode.is_active = isActive;
                promocode.updated_at = res.updated_at
                return of(res);
            }, err => of([]))
        )
    }

    /**
    * Delete
    * @param promocodeId
    */
    deletePromocode(promocodeId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/promocodes/${promocodeId}/`).pipe(
            tap((res: any) => {
                this._promocodes.next(this._promocodes.value.filter(code => code.id !== promocodeId));
                return of(res);
            }, err => of([]))
        )
    }

    /**
    * Add new promocode
    *
    * @param PromocodeDetails
    */
    createPromocode(credentials: {
        code: string;
        product_id: string;
        fixed_price_deduction: number;
        percentage_deduction: number;
        deduction_limit: number;
        valid_till: string;
        is_active: boolean;
    }): Observable<any> {
        credentials.valid_till = new DatePipe('en-US').transform(credentials.valid_till, 'd/M/yyyy');
        return this._httpClient.post(`${BASE_URL}administration/promocodes/`, credentials);
    }

    /**
         * Update
         * @param isActive 'true' to activate 'false' to deactivate
         * @param promocodeId
         */
    updatePromocodeStatus(promocodeId:number, promocode: Promocode): Observable<any> {
        promocode.valid_till = formatDate(promocode.valid_till,'d/M/yyyy', 'en-US')
        return this._httpClient.put(`${BASE_URL}administration/promocodes/${promocodeId}/`, promocode).pipe(
            tap((res: any) => {
                return of(res);
            }, err => of([]))
        )
    }



}
