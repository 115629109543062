import { inject } from '@angular/core';
import { forkJoin } from 'rxjs';
import { SubscriptionService } from 'app/core/subscription/subscription.service';
import { ActivatedRouteSnapshot } from '@angular/router';


export const digitalPlanDetailDataResolver = (route: ActivatedRouteSnapshot) => {
    const _subscriptionService = inject(SubscriptionService);

    return forkJoin([
        _subscriptionService.getDigitalPlanDetails(route.params['id']),
        _subscriptionService.getPlanCategories(),
        _subscriptionService.getDigitalPlanGroups(0, 100, null, null, null, null),
    ]);
};