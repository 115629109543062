import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SubscriptionService } from 'app/core/subscription/subscription.service';
import { InitialData } from './digital-subscription-detail.types';
import { Subscription } from 'app/core/subscription/subscription.model';

@Injectable({
    providedIn: 'root'
})
export class DigitalSubscriptionDetailDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _subscriptionService: SubscriptionService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._subscriptionService.getDigitalSubscriptionDetails(route.params['id'])
            .pipe(
                map((details) => ({
                    details
                })),
                catchError(err => {
                    this._subscriptionService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().subscriptionDetail);
                })
            );
    }

}

class DefaultResponse implements InitialData {
    subscriptionDetail: any = [];
}