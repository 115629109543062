import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Banner } from './banner.model';

@Injectable({
    providedIn: 'root'
})
export class BannerService {

    private _banners: BehaviorSubject<Banner[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for banners
     */
    get banners$(): Observable<Banner[]> {
        return this._banners.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get banner list 
     */
    getBanners(): Observable<Banner[]> {
        return this._httpClient.get<Banner[]>(`${BASE_URL}administration/banners/`).pipe(
            tap((response) => {
                this.errorMessage = response ? "" : "No banners";
                this._banners.next(response ? response : []);
            })
        );
    }


    /**
    * Delete
    * @param bannerId
    */
    deleteBanner(bannerId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/banners/${bannerId}/`).pipe(
            tap((res: any) => {
                this._banners.next(this._banners.value.filter(banner => banner.id !== bannerId));
                return of(res);
            }, err => of([]))
        )
    }

    /**
    * Add new banner
    *
    * @param BannerDetails
    */
     createBanner(formData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/banners/`, formData);
    }

    /**
    * Update banner
    *
    * @param BannerDetails
    */
    updateBanner(bannerId: number,formData): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/banners/${bannerId}/`, formData);
    }

}
