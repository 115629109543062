import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Pagination } from 'app/shared/pagination.type';
import { Order, OrderResponse } from './order.model';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ User Management
    // -----------------------------------------------------------------------------------------------------

    private _orders: BehaviorSubject<Order[] | null> = new BehaviorSubject(null);
    private _order: BehaviorSubject<Order | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string;

    get orders$(): Observable<Order[]> {
        return this._orders.asObservable();
    }

    get orderDetail$(): Observable<Order> {
        return this._order.asObservable();
    }

    getOrdersInitial(type: string): Observable<OrderResponse> {
        return this.getOrders(null, null, null, null, null, type);
    }

    /**
    * Get order list with search and sort
    */
    getOrders(page: number = 0, batch_size: number = 10, sort: string = 'added_at', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, orderType: string): Observable<OrderResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<OrderResponse>(`${BASE_URL}administration/orders/`, {
            params: {
                page: page ? ++page : 1,
                sort: sort ? sort : '',
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 10,
                status: orderType ? orderType : 'all'
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._orders.next(response.orders ? response.orders : []);
            })
        );
    }

    /**
    * Get order details
    */
    getOrderDetail(orderId: number = 0): Observable<Order> {
        return this._httpClient.get<Order>(`${BASE_URL}administration/orders/${orderId}/`).pipe(
            tap((response) => {
                this._order.next(response);
            })
        );
    }

    /**
    * Download order as zip file
    */
    getOrderDetailZipFile(orderId: number = 0): any {
        return this._httpClient.get(`${BASE_URL}administration/orders/${orderId}/export/`, {
            responseType: 'arraybuffer'
        });
    }

    /**
    * Add tracking details
    *
    * @param Tracking Details
    */
    addTracking(orderId: number = 0, credentials: {
        code: string;
        product_id: string;
    }): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/orders/${orderId}/tracking/`, credentials);
    }

    /**
    * Update Status
    *
    * @param Status
    */
    updateStatus(orderId: number = 0, status: String): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/orders/${orderId}/status/`, { status: status });
    }

    updateProof(formData, orderId, itemId): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/orders/${orderId}/proofs/${itemId}/`, formData);
    }

    deleteProofs(orderId, itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/orders/${orderId}/proofs/${itemId}/`);
    }

    // Vendor Order details
    addVendorOrder(orderId: number = 0, vendorOrderId: number = 0, vendorId: String): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/orders/${orderId}/vendor/`, {
            vendor_id: vendorId, order_id: vendorOrderId
        }).pipe(
            tap((response) => {
                this._order.next(response);
            })
        );
    }

    // Vendor Order details
    updateVendorOrder(orderId: number = 0, orderItemId: String, data): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/orders/${orderId}/vendor/${orderItemId}/`, data).pipe(
            tap((response) => {
                this._order.next(response);
            })
        );
    }

}
