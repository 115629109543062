import { inject } from '@angular/core';
import { SubscriptionService } from 'app/core/subscription/subscription.service';
import { forkJoin } from 'rxjs';

export const digitalSubscriptionsDataResolver = () => {
    const _subscriptionService = inject(SubscriptionService);

    return forkJoin([
        _subscriptionService.getDigitalSubscriptionsInitial(),
        _subscriptionService.getPlanCategories(),
    ]);
};