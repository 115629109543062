import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Pagination } from 'app/shared/pagination.type';
import { Tax, TaxResponse } from './tax.model';

@Injectable({
    providedIn: 'root'
})
export class TaxService {

    private _taxes: BehaviorSubject<Tax[] | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for taxes
     */
    get taxes$(): Observable<Tax[]> {
        return this._taxes.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get tax list 
     */
    getTaxes(page: number = 0, batch_size: number = 10, query: string): Observable<TaxResponse> {
        return this._httpClient.get<TaxResponse>(`${BASE_URL}administration/sales-tax-rates/`, {
            params: {
                page: page ? ++page : 1,
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._taxes.next(response.tax_rates ? response.tax_rates : []);
            })
        );
    }

    uploadTaxFiles(formData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/sales-tax-rates/`, formData);
    }

}
