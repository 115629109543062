import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BannerService } from 'app/core/banner/banner.service';
import { InitialData } from 'app/modules/admin/manage-banner/banner.types';

@Injectable({
    providedIn: 'root'
})
export class BannerDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _bannerService: BannerService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._bannerService.getBanners()
            .pipe(
                map((banners) => ({
                    banners
                })
                ),
                catchError(err => {
                    this._bannerService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().banners);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    banners: any = [];
}