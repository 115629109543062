import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import {  Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrderService } from 'app/core/order/order.service';
import { InitialData } from './order.types';

@Injectable({
    providedIn: 'root'
})
export class OrderDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _orderService: OrderService, private router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        // API call to get orders llst
        return this._orderService.getOrdersInitial(this.getCurrentRoute(state.url))
            .pipe(
                map((orders) => ({
                    orders
                })
                ),
                catchError(err => {
                    this._orderService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().orders);
                })
            );
    }

    private getCurrentRoute(url:string) : string {
        switch(url)
        {
            case '/order/all' : {
                return 'all';
            }
            case '/orders' : {
                return 'all';
            }
            case '/order/new' : {
                return 'processing';
            }
            case '/order/processed' : {
                return 'processed';
            }
            case '/order/shipped' : {
                return 'shipped';
            }
            case '/order/delivered' : {
                return 'delivered';
            }
            case '/order/cancelled' : {
                return 'cancelled';
            }
            default :{
                return 'all';
            }
        }
    }
}

class DefaultResponse implements InitialData {
    orders: any = [];
}