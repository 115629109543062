import { FuseNavigationItem } from "@fuse/components/navigation";
import { MARKETING_URL, environment } from "environments/environment";
import { CookieService } from "ngx-cookie-service";
import { ReplaySubject } from "rxjs";
import { AuthService } from '../core/auth/auth.service';

export class PWANavigation {

    constructor(private cookie: CookieService) {
    }

    private _navigation: ReplaySubject<FuseNavigationItem[]> = new ReplaySubject<FuseNavigationItem[]>(1);
    navigation: FuseNavigationItem[] = [

        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'basic',
            icon: 'heroicons_outline:home',
            link: '/dashboard'
        },
        {
            id: 'reoprt',
            title: 'Reports',
            type: 'basic',
            icon: 'mat_outline:insert_chart_outlined',
            link: '/reports'
        },
        {
            id: 'configure',
            title: 'Configure',
            type: 'collapsable',
            icon: 'mat_outline:settings_input_component',
            children: [
                {
                    id: 'configure.autofill',
                    title: 'Autofill',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/configuration/autofill'
                },
                // {
                //     id: 'configure.configurations',
                //     title: 'Configurations',
                //     type: 'basic',
                //     icon: 'heroicons_outline:arrow-small-right',
                //     link: '/configuration/manage'
                // },
                {
                    id: 'configure.size',
                    title: 'Sizes',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/size/manage'
                },
                {
                    id: 'configure.category',
                    title: 'Categories',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/category/manage'
                },
                {
                    id: 'configure.color',
                    title: 'Colors',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/color/manage'
                },
                {
                    id: 'configure.keyword',
                    title: 'Keywords',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/keyword/manage'
                },
                {
                    id: 'configure.tag',
                    title: 'Franchises',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/franchise-categories/manage'
                }
            ]
        },
        {
            id: 'user-management',
            title: 'Users',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/user/manage'
        },
        {
            id: 'admin-management',
            title: 'Admins',
            type: 'basic',
            icon: 'heroicons_outline:user',
            link: '/admin/manage'
        },
        {
            id: 'email-signature',
            title: 'Email Signatures',
            type: 'basic',
            icon: 'mat_outline:draw',
            link: '/email-signature/manage'
        },
        {
            id: 'product-management',
            title: 'Products',
            type: 'basic',
            icon: 'mat_outline:view_list',
            link: '/product/manage'
        },
        {
            id: 'accessory-management',
            title: 'Accessories',
            type: 'basic',
            icon: 'mat_outline:post_add',
            link: '/accessory/manage'
        },
        {
            id: 'configure.promocode',
            title: 'Promocodes',
            type: 'basic',
            icon: 'heroicons_outline:ticket',
            link: '/promocode/manage'
        },
        {
            id: 'configure.tax',
            title: 'Sales Taxes',
            type: 'basic',
            icon: 'heroicons_outline:receipt-percent',
            link: '/tax/manage'
        },
        {
            id: 'orders',
            title: 'Order',
            type: 'collapsable',
            icon: 'heroicons_outline:shopping-cart',
            children: [
                {
                    id: 'order.all',
                    title: 'All Orders',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/order/all'
                },
                {
                    id: 'order.new',
                    title: 'New Orders',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/order/new'
                },
                {
                    id: 'order.processed',
                    title: 'Processed Orders',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/order/processed'
                },
                {
                    id: 'order.shipped',
                    title: 'Shipped Orders',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/order/shipped'
                },
                {
                    id: 'order.delivered',
                    title: 'Delivered Orders',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/order/delivered'
                },
                {
                    id: 'order.cancelled',
                    title: 'Cancelled Orders',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/order/cancelled'
                },
                {
                    id: 'order.feedback',
                    title: 'Feedbacks',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/order/feedbacks'
                }
            ]
        },
        {
            id: 'banner-management',
            title: 'Banners',
            type: 'basic',
            icon: 'heroicons_outline:newspaper',
            link: '/banner/manage'
        },
        {
            id: 'subscription',
            title: 'Subscription',
            type: 'collapsable',
            icon: 'heroicons_outline:credit-card',
            children: [
                // {
                //     id: 'subscription.feature',
                //     title: 'Features',
                //     type: 'basic',
                //     icon: 'heroicons_outline:arrow-small-right',
                //     link: '/subscription/features'
                // },
                // {
                //     id: 'subscription.category',
                //     title: 'Categories',
                //     type: 'basic',
                //     icon: 'heroicons_outline:arrow-small-right',
                //     link: '/subscription/category'
                // },
                // {
                //     id: 'subscription.plans',
                //     title: 'Plans',
                //     type: 'basic',
                //     icon: 'heroicons_outline:arrow-small-right',
                //     link: '/subscription/plans'
                // },
                // {
                //     id: 'subscription.interest',
                //     title: 'Interests',
                //     type: 'basic',
                //     icon: 'heroicons_outline:arrow-small-right',
                //     link: '/subscription/interests'
                // },
                {
                    id: 'digital_plan_group',
                    title: 'Groups',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/subscription/groups'
                },
                {
                    id: 'digital_plans',
                    title: 'Plans',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/subscription/plans'
                },
                {
                    id: 'digital_subscription',
                    title: 'Subscriptions',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/subscription/list'
                }
            ]
        },
        {
            id: 'faq',
            title: 'FAQ',
            type: 'collapsable',
            icon: 'heroicons_outline:question-mark-circle',
            children: [
                {
                    id: 'faq.category',
                    title: 'Categories',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/faq/category/manage'
                },
                {
                    id: 'faq.category',
                    title: 'FAQs',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/faq/manage'
                },
                {
                    id: 'faq.video',
                    title: 'Videos',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-small-right',
                    link: '/faq/video'
                }
            ]
        },
        {
            id: 'marketing',
            title: 'Marketing Dashboard',
            function: (item: FuseNavigationItem) => { this.saveMarketingCookie() },
            type: 'basic',
            icon: 'heroicons_outline:newspaper',
            externalLink: true,
            link: MARKETING_URL
        },
    ];



    saveMarketingCookie() {

        let DOMAIN = environment.production ? 'printingwarehouses.com' : 'printingw.xyz'
        this.cookie.set('marketing_token', localStorage.getItem('accessToken') ?? '', { domain: DOMAIN, sameSite: 'None', expires: new Date(2030, 1, 1), path: '/', secure: true });
        // this.cookie.set('token', this.authService.accessToken, { domain: DOMAIN, sameSite: 'None', expires: new Date(2030, 1, 1), path: '/', secure: true });

    }
}