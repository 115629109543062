<div class="absolute inset-0 flex flex-col min-w-0 overflow-auto bg-transparent">
    <!-- Main -->
    <div class="flex-auto" style="overflow: auto;" [ngClass]="{'disableDiv': isLoadingEmitter$ | async}">
        <div class="w-full max-w-screen-xl mx-auto">

            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 w-full min-w-0 p-6 ">
                <div class="flex md:col-span-4 sm:col-span-2 items-start justify-between mt-3 sm:mt-0 sm:ml-2">
                    <div class="text-lg font-medium tracking-tight leading-6 truncate">Overview</div>
                    <div>
                        <button class="h-6 min-h-6 px-2  bg-hover" mat-button [matMenuTriggerFor]="filterMenu">
                            <span class="font-medium text-sm text-secondary">{{overviewFilter}}</span>
                        </button>
                        <mat-menu #filterMenu="matMenu">
                            <button mat-menu-item (click)="onChangeOverviewPeriod('all','All Time')">All
                                Time</button>
                            <button mat-menu-item (click)="onChangeOverviewPeriod('day','Day')">Day</button>
                            <button mat-menu-item (click)="onChangeOverviewPeriod('month','Month')">Month</button>
                            <button mat-menu-item (click)="onChangeOverviewPeriod('year','Year')">Year</button>
                        </mat-menu>
                    </div>
                </div>
                <!-- Order Summary -->
                <div
                    class="flex flex-col md:col-span-2 lg:col-span-1 flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex items-start justify-between">
                        <div class="flex flex-col items-left mt-2">
                            <div class="text-2xl sm:text-4xl tracking-tight leading-none" style="color:#061058">
                                {{orderSummary?.orders? orderSummary.orders:0}}
                            </div>
                            <div class="text-sm " style="color: #8287ab;">Total orders</div>

                        </div>
                        <div class="items-center mt-2" style="height: 100%;">
                            <img class="text-center w-12 align-baseline" src="assets/images/dashboard/order.png">
                        </div>
                    </div>
                    <a class="flex w-full mt-2 text-secondary" [routerLink]="'/order/new'">
                        <div class="text text-md font-semibold truncate" style="color: #0e29db;">
                            {{orderSummary?.new_orders? orderSummary?.new_orders:0}}</div>
                        <div class="ml-1.5 text-md font-semibold" style="color: #0e29db;">New Orders - ></div>
                    </a>
                </div>
                <!-- Users Summary -->
                <div
                    class="flex flex-col md:col-span-2 lg:col-span-1 flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex items-start justify-between">
                        <div class="flex flex-col items-left mt-2">
                            <div class="text-2xl sm:text-4xl tracking-tight leading-none" style="color:#061058">
                                {{orderSummary?.total_users? orderSummary?.total_users:0}}
                            </div>
                            <div class="text-sm" style="color: #8287ab;">Total Users</div>
                        </div>
                        <div class="items-center mt-2" style="height: 100%;">
                            <img class="text-center w-12 align-baseline" src="assets/images/dashboard/user.png">
                        </div>
                    </div>
                    <a class="flex w-full mt-2 text-secondary" [routerLink]="'/user/manage'">
                        <div class="text-md font-semibold truncate" style="color: #0e29db;">
                            {{orderSummary?.new_users?orderSummary?.new_users:0}}</div>
                        <div class="ml-1.5 text-md font-semibold" style="color: #0e29db;">New Users - ></div>
                    </a>
                </div>
                <!-- Revenue Summary -->
                <div
                    class="flex flex-col md:col-span-2 lg:col-span-1 flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex items-start justify-between">
                        <div class="flex flex-col items-left mt-2">
                            <div class="text-2xl sm:text-4xl tracking-tight leading-none" style="color:#061058">
                                ${{(orderSummary?.total_revenue? orderSummary?.total_revenue:0) | number : '1.2-2'}}
                            </div>
                            <div class="text-sm" style="color: #8287ab;">Total Revenue</div>
                            <div class="flex w-full mt-2 text-secondary invisible">
                                <div class="text-md font-semibold truncate" style="color: #0e29db;">
                                    revenue</div>
                                <div class="ml-1.5 text-md font-semibold hidden" style="color: #0e29db;">revenue
                                </div>
                            </div>
                        </div>
                        <div class="items-center mt-2" style="height: 100%;">
                            <img class="text-center w-12 align-baseline" src="assets/images/dashboard/revenue.png">
                        </div>
                    </div>
                </div>
                <!-- Subscription Summary -->
                <div
                    class="flex flex-col md:col-span-2 lg:col-span-1 flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex items-start justify-between">
                        <div class="flex flex-col items-left mt-2">
                            <div class="text-2xl sm:text-4xl tracking-tight leading-none" style="color:#061058">
                                {{orderSummary?.total_subscriptions}}
                            </div>
                            <div class="text-sm" style="color: #8287ab;">Total Subscribers</div>
                        </div>
                        <div class="items-center mt-2" style="height: 100%;">
                            <img class="text-center w-12 align-baseline" src="assets/images/dashboard/order.png">
                        </div>
                    </div>
                    <div class="flex w-full mt-2 text-secondary">
                        <div class="text-md font-semibold truncate" style="color: #0e29db;">
                            {{orderSummary?.new_subscriptions}}</div>
                        <div class="ml-1.5 text-md font-semibold" style="color: #0e29db;">New Subscribers - >
                        </div>
                    </div>
                </div>

                <!-- Revenue -->
                <div
                    class="sm:col-span-2 md:col-span-4 lg:col-span-2 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex flex-row items-start justify-between">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">Revenue </div>
                        <div class="sm:mt-0 sm:ml-2">
                            <button class="h-6 min-h-6 px-2  bg-hover" mat-button [matMenuTriggerFor]="revenueFilterMenu">
                                <span class="font-medium text-sm text-secondary">{{revenueFilter}}</span>
                            </button>
                            <mat-menu #revenueFilterMenu="matMenu">
                                <button mat-menu-item (click)="onChangeRevenuePeriod('all','All Time')">All
                                    Time</button>
                                <button mat-menu-item (click)="onChangeRevenuePeriod('day','Day')">Day</button>
                                <button mat-menu-item (click)="onChangeRevenuePeriod('month','Month')">Month</button>
                                <button mat-menu-item (click)="onChangeRevenuePeriod('year','Year')">Year</button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="flex flex-col flex-auto">
                        <apx-chart *ngIf="hasRevenueData" [series]="chartOptions?.series" [chart]="chartOptions?.chart"
                            [xaxis]="chartOptions?.xaxis" [yaxis]="chartOptions?.yaxis"
                            [dataLabels]="chartOptions?.dataLabels" [grid]="chartOptions?.grid"
                            [stroke]="chartOptions?.stroke" [title]="chartOptions?.title">
                        </apx-chart>
                        <div *ngIf="!hasRevenueData" class="text-xl tracking-tight leading-none" style="margin: auto; color:#061058">
                           No data available for selected filter
                        </div>
                    </div>

                </div>
                <!-- Sales By category -->
                <div
                    class="sm:col-span-2 md:col-span-4 lg:col-span-2 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex flex-row items-start justify-between">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">Sales by Category </div>
                        <div class="sm:mt-0 sm:ml-2">
                            <button class="h-6 min-h-6 px-2  bg-hover" mat-button
                                [matMenuTriggerFor]="categoryFilterMenu">
                                <span class="font-medium text-sm text-secondary">{{orderCategoryFilter}}</span>
                            </button>
                            <mat-menu #categoryFilterMenu="matMenu">
                                <button mat-menu-item (click)="onChangeOrdersCategoryPeriod('all','All Time')">All
                                    Time</button>
                                <button mat-menu-item (click)="onChangeOrdersCategoryPeriod('day','Day')">Day</button>
                                <button mat-menu-item
                                    (click)="onChangeOrdersCategoryPeriod('month','Month')">Month</button>
                                <button mat-menu-item
                                    (click)="onChangeOrdersCategoryPeriod('year','Year')">Year</button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="flex flex-col flex-auto mt-1 align-middle">
                        <div *ngFor="let category of orderCategorySummary">
                            <div class="text font-medium mt-4" style="color: #8287ab;">{{category.title}}</div>
                            <div class="flex flex-row flex-auto mt-1">
                                <mat-progress-bar class="business-cards-progress-bar self-center" mode="determinate"
                                    [value]='category.percentage' [appProgressBarColor]="'cyan'"></mat-progress-bar>
                                <div class="ml-2 text-lg font-medium self-center">{{category.percentage |number :
                                    '1.0-0'}}%</div>
                            </div>
                        </div>
                        
                        <div *ngIf="orderCategorySummary ? orderCategorySummary.length == 0 : true" class="text-xl p-8 tracking-tight leading-none" style="margin: auto; color:#061058">
                            No data available for selected filter
                         </div>
                    </div>

                </div>

                <!-- Orders -->
                 <!-- Main -->

        <!-- Orders list -->
        <div class="sm:col-span-2 md:col-span-4 lg:col-span-4 flex flex-col flex-auto bg-card shadow rounded-2xl ">
            <div class="flex flex-row items-start justify-between p-6 ">
                <div class="text-lg font-medium tracking-tight leading-6 truncate">Recent Orders </div>
                <div class=" sm:mt-0 sm:ml-2" *ngIf="orderCount > 0">
                    <button class="h-6 min-h-6 px-2  bg-hover" mat-button [routerLink]="'/order/new'">
                        <span class="font-medium text-sm text-secondary">View More</span>
                    </button>
                </div>
            </div>
            <ng-container *ngIf="orderCount > 0; else noOrders">
                <!-- Table wrapper -->
                <div class="overflow-x-auto overflow-y-auto"  cdkScrollable>

                    <!-- Table -->
                    <table class="w-full min-w-280 table-fixed bg-transparent"
                        [ngClass]="{'pointer-events-none': isLoading}" mat-table matSort [matSortActive]="'added_at'"
                        [matSortDisableClear]="true" [matSortDirection]="'asc'" [multiTemplateDataRows]="true"
                        [dataSource]="orders$" [trackBy]="trackByFn">
                        <ng-container matColumnDef="slNo">
                            <th class="w-20 bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell
                                *matHeaderCellDef disableClear>
                                Sl No
                            </th>
                            <td class="pr-4 break-words" mat-cell *matCellDef="let order; let i = renderIndex;">
                                {{  i+1 }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="id">
                            <th class="w-16 bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell
                                *matHeaderCellDef disableClear>
                                Id
                            </th>
                            <td class="pr-4 break-words" mat-cell *matCellDef="let order">
                                {{order.id}}
                            </td>
                        </ng-container>
                        <!-- Reference Number -->
                        <ng-container matColumnDef="reference_id">
                            <th class="w-56 bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell
                                *matHeaderCellDef disableClear>
                                Reference Number
                            </th>
                            <td class="pr-8 break-words" mat-cell *matCellDef="let order">
                                {{order.reference_id}}
                            </td>
                        </ng-container>

                        <!-- Placed by -->
                        <ng-container matColumnDef="placed_by_name">
                            <th class="w-56 bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell
                                *matHeaderCellDef mat-sort-header disableClear>
                                Placed By
                            </th>
                            <td class="pr-4 break-words" mat-cell *matCellDef="let order">
                                {{order.placed_by_name}}
                            </td>
                        </ng-container>

                        <!-- Price -->
                        <ng-container matColumnDef="total_price">
                            <th class="w-30  bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell
                                *matHeaderCellDef  mat-sort-header disableClear>
                                Total Price
                            </th>
                            <td class="pr-4 break-words" mat-cell *matCellDef="let order">
                                ${{order.total_price}}
                            </td>
                        </ng-container>

                        <!-- Added Date -->
                        <ng-container matColumnDef="added_at">
                            <th class="w-30  bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell
                                *matHeaderCellDef mat-sort-header disableClear>
                                Added Date
                            </th>
                            <td class="pr-4 break-words" mat-cell *matCellDef="let order">
                                {{order.added_at | date:'MMM dd yyyy hh:mm aa'}}
                            </td>
                        </ng-container>

                        <!-- Status -->
                        <ng-container matColumnDef="status">
                            <th class="w-30  bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell
                                *matHeaderCellDef mat-sort-header disableClear>
                                Status
                            </th>
                            <td class="pr-4 capitalize" mat-cell *matCellDef="let order">
                                {{order.status?.replaceAll('_',' ')}}
                            </td>
                        </ng-container>

                        <!-- Actions -->
                        <ng-container matColumnDef="actions">
                            <th class="w-32 bg-gray-50 dark:bg-black dark:bg-opacity-5" mat-header-cell
                                *matHeaderCellDef disableClear>
                                Actions
                            </th>
                            <td class="pr-4 text-center" mat-cell *matCellDef="let order">
                                <button mat-flat-button [color]="'primary'" 
                                    (click)="viewOrderDetail(order,true)"  [routerLink]="['/order', order.id]">
                                    <span class="ml-2 mr-1">Details</span>
                                </button>
                            </td>
                        </ng-container>

                        <tr class="shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr class="h-18 hover:bg-gray-100 dark:hover:bg-hover" mat-row
                            *matRowDef="let product; columns: displayedColumns;"></tr>

                        <!-- <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                            <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr> -->
                        <!-- <tr
                            class="h-0"
                            mat-row
                            *matRowDef="let row; columns: ['productDetails']"></tr> -->

                    </table>

                </div>          
            </ng-container>

            <ng-template #noOrders>
                <div  class="text-xl p-8 tracking-tight leading-none" style="margin: auto; color:#061058">
                    There are no recent orders to show
                </div>
            </ng-template>

        </div>
            </div>

        </div>


    </div>
</div>