import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Size, SizeDetail, SizeToDisplay } from './size.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class SizeService {

    private _sizes: BehaviorSubject<SizeToDisplay[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for sizes
     */
    get sizes$(): Observable<SizeToDisplay[]> {
        return this._sizes.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get size list 
     */
    getSizes(): Observable<SizeDetail[]> {
        return this._httpClient.get<SizeDetail[]>(`${BASE_URL}administration/product-sizes/`).pipe(
            tap((response) => {
                let sizesToDisplay: SizeToDisplay[] = []
                response.forEach(size => {
                    size.available_sizes.forEach(availableSize => {
                        sizesToDisplay.push({ category: size.category, subCategory: size.sub_category, category_id: size.category_id, sub_category_id: size.sub_category_id, width: availableSize.width, height: availableSize.height, id: availableSize.id })
                    })
                })
                this._sizes.next(sizesToDisplay ? sizesToDisplay : []);
                // return of(this._sizes.value)
            })
        );
    }

    /**
     * Delete Size
     * @param sizeId
     */
    deleteSize(sizeId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/product-size/${sizeId}/`).pipe(
            tap((res: any) => {
                this._sizes.next(this._sizes.value.filter(size => size.id !== sizeId));
            }, err => of([]))
        )
    }


    createSize(credentials: { sub_category_id: number; width: number; height: number; }): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/product-size/`, credentials);
    }

    updateSize(credentials: { sub_category_id: number; width: number; height: number; }, sizeId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/product-size/${sizeId}/`, credentials);
    }

}
