import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { AutofillData, Product, ProductNameList, ProductVersion, ProductVersionFranchise, ProductVersionFranchiseResponse, ProductVersionResponse, Vendor, VersionDesign } from './product.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    private _products: BehaviorSubject<Product[] | null> = new BehaviorSubject(null);
    private _productDetails: BehaviorSubject<Product | null> = new BehaviorSubject(null);
    private _versionDetails: BehaviorSubject<ProductVersion | null> = new BehaviorSubject(null);

    errorMessage: string = "Something went wrong ";
    errorMessageFranchise: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for products
     */
    get products$(): Observable<Product[]> {
        return this._products.asObservable();
    }

    /**
     * Getter for productDetails
     */
    get productDetails$(): Observable<Product> {
        return this._productDetails.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
         * Get accessory list 
         */
    getAccessories(): Observable<Product[]> {
        return this._httpClient.get<Product[]>(`${BASE_URL}administration/products/accessories/`).pipe(
            tap((response) => {
                this.errorMessage = response ? "" : "No accessories";
                this._products.next(response ? response : []);
            })
        );
    }

    /**
     * Get product list 
     */
    getProducts(): Observable<Product[]> {
        return this._httpClient.get<Product[]>(`${BASE_URL}administration/products/`).pipe(
            tap((response) => {
                this.errorMessage = response ? "" : "No products";
                this._products.next(response ? response : []);
            })
        );
    }

    /**
     * Get product name list 
     */
    getProductsNames(categoryId: number = null): Observable<any> {
        let params = {
            category_id: categoryId
        }
        return this._httpClient.get<ProductNameList[]>(`${BASE_URL}administration/products/names/`, { params: params });
    }

    /**
     * Get vendor list 
     */
    getVendors(): Observable<Vendor[]> {
        return this._httpClient.get<Vendor[]>(`${BASE_URL}administration/vendors/`);
    }

    /**
    * Delete
    * @param productId
    */
    deleteProduct(productId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/products/${productId}/`).pipe(
            tap((res: any) => {
                this._products.next(this._products.value.filter(product => product.id !== productId));
                return of(res);
            }, err => of([]))
        )
    }

    /**
    * Add new product
    *
    * @param ProductDetails
    */
    createProduct(formData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/products/`, formData);
    }

    /**
    * Change premium status of product
    *
    * @param ProductDetails
    */
    changePremiumStatus(productId: number, product: Product): Observable<any> {
        let tempProduct = this._products.value.find(p => p.id == productId);
        if (product.is_premium != null)
            tempProduct.is_premium = product.is_premium
        this._products.next(this._products.value);
        return this._httpClient.put(`${BASE_URL}administration/products/${productId}/`, product).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                if (response?.id != null) {
                    //Change local values with latest values
                    let tempProduct = this._products.value.find(p => p.id == productId);
                    tempProduct.is_premium = response.is_premium;
                    this._products.next(this._products.value);
                }
            }, err => {
                //Revert values
                let tempProduct = this._products.value.find(p => p.id == productId);
                if (product.is_premium != null)
                    tempProduct.is_premium = !product.is_premium
                this._products.next(this._products.value);
            }));
    }

    /**
    * Change active status of product
    *
    * @param ProductDetails
    */
    changeActiveStatus(productId: number, product: Product): Observable<any> {
        let tempProduct = this._products.value.find(p => p.id == productId);
        if (product.is_active != null)
            tempProduct.is_active = product.is_active
        this._products.next(this._products.value);
        return this._httpClient.put(`${BASE_URL}administration/products/${productId}/`, product).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                if (response?.id != null) {
                    //Change local values with latest values
                    let tempProduct = this._products.value.find(p => p.id == productId);
                    tempProduct.is_active = response.is_active;
                    this._products.next(this._products.value);
                }
            }, err => {
                //Revert values
                let tempProduct = this._products.value.find(p => p.id == productId);
                if (product.is_active != null)
                    tempProduct.is_active = !product.is_active
                this._products.next(this._products.value);
            }));
    }

    /**
    * Update product
    *
    * @param ProductDetails
    */
    updateProduct(productId: number, product: Product): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/products/${productId}/`, product).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                if (response?.id != null) {
                    this._productDetails.next(response);
                }
            }));
    }

    /**
     * Get product details 
     */
    getProductDetails(productId: number): Observable<Product> {
        return this._httpClient.get<Product>(`${BASE_URL}administration/products/${productId}/`).pipe(
            tap((response) => {
                this.errorMessage = response ? "" : "Something went wrong!";
                this._productDetails.next(response);
            })
        );
    }


    //Product version management
    private _versions: BehaviorSubject<ProductVersion[] | null> = new BehaviorSubject(null);
    pagination: Pagination;
    get productVersions$(): Observable<ProductVersion[]> {
        return this._versions.asObservable();
    }

    removeVersion(versionId) {
        this._versions.next(this._versions.value.filter(version => version.id !== versionId));
    }

    //Product version management
    private _versionsFranchise: BehaviorSubject<ProductVersionFranchise[] | null> = new BehaviorSubject(null);
    paginationFranchise: Pagination;
    get productVersionsFranchise$(): Observable<ProductVersionFranchise[]> {
        return this._versionsFranchise.asObservable();
    }

    /**
     * Getter for version details
     */
    get versionDetails$(): Observable<ProductVersion> {
        return this._versionDetails.asObservable();
    }

    /**
    * Get versions list with pagination 
    */
    getVersions(productId: number, page: number = 0, batch_size: number = 10, showOnlyProductsWithWarning: boolean, search:string): Observable<ProductVersionResponse> {
        return this._httpClient.get<ProductVersionResponse>(`${BASE_URL}administration/product/versions/${productId}/`, {
            params: {
                search: search,
                page: page ? ++page : 1,
                batch_size: batch_size ? batch_size : 10,
                show_products_with_warning: showOnlyProductsWithWarning ? 1 : 0
            }
        }).pipe(
            tap((response: ProductVersionResponse) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._versions.next(response.product_versions ? response.product_versions : []);
            })
        );
    }

    /**
     * Delete Version
     */
    deleteVersion(versionId: number): Observable<any> {
        return this._httpClient.delete<any>(`${BASE_URL}administration/product/version/${versionId}/`).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this._versions.next(this._versions.value.filter(version => version.id !== versionId));
            })
        );
    }

    /**
    * Add new version
    *
    * @param VersonDetails
    */
    createVersion(productId: number, formData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/product/versions/${productId}/`, formData);
    }


    /**
    * Update Version
    *
    * @param VersionDetails
    */
    updateVersion(versionId: number, version): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/product/version/${versionId}/`, version).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                if (response?.id != null) {
                    this._versionDetails.next(response);
                }
            }));
    }

    /**
     * Get version details 
     */
    getVersionDetails(versionId: number): Observable<ProductVersion> {
        return this._httpClient.get<ProductVersion>(`${BASE_URL}administration/product/version/${versionId}/`).pipe(
            tap((response) => {
                this.errorMessage = response ? "" : "Something went wrong!";
                this._versionDetails.next(response);
            })
        );
    }

    //Version design management
    private _designs: BehaviorSubject<VersionDesign[] | null> = new BehaviorSubject(null);
    get versionDesigns$(): Observable<VersionDesign[]> {
        return this._designs.asObservable();
    }

    /**
   * Get versions list with pagination 
   */
    getVersionsWithFranchise(productId: number, page: number = 0, batch_size: number = 10, showAll: boolean): Observable<ProductVersionFranchiseResponse> {
        return this._httpClient.get<ProductVersionFranchiseResponse>(`${BASE_URL}administration/product/versions/${productId}/franchise/`, {
            params: {
                page: page ? ++page : 1,
                batch_size: batch_size ? batch_size : 10,
                show_all: showAll ? 1 : 0
            }
        }).pipe(
            tap((response: ProductVersionFranchiseResponse) => {
                this.errorMessageFranchise = response.message;
                this.paginationFranchise = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._versionsFranchise.next(response.product_versions ? response.product_versions : []);
            })
        );
    }

    updateVersionFranchise(productId: number, versions, franchise) {
        let data = {
            "versions": versions,
            "franchise": franchise
        }
        return this._httpClient.put<ProductVersionFranchiseResponse>(`${BASE_URL}administration/product/versions/${productId}/franchise/`, data)
    }

    /**
    * Get designs list
    */
    getdesigns(versionId: number): Observable<VersionDesign[]> {
        return this._httpClient.get<VersionDesign[]>(`${BASE_URL}administration/product/version/${versionId}/designs/`).pipe(
            tap((response: VersionDesign[]) => {
                this._designs.next(response ? response : []);
            })
        );
    }

    /**
     * Delete design
     */
    deleteDesign(versionId: number, designId: number): Observable<any> {
        return this._httpClient.delete<any>(`${BASE_URL}administration/product/version/${versionId}/designs/${designId}/`).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this._designs.next(this._designs.value.filter(design => design.id !== designId));
            })
        );
    }

    /**
     * Delete Image
     */
     deleteVersionImage(versionId: number, imageId: number): Observable<any> {
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: {
                image_id : imageId
            },
          };
        return this._httpClient.delete<any>(`${BASE_URL}administration/product/version/${versionId}/`,options).pipe(
            tap((response) => {
                if(this.versionDetails$ != null)
                {
                    let tempVersion = this._versionDetails.value
                    tempVersion.image_urls = tempVersion.image_urls.filter(image=> {
                        return  image.id != imageId
                    })
                    this._versionDetails.next(tempVersion);
                }
                // this._versions.next(this._versions.value.filter(version => version.id !== versionId));
            })
        );
    }

    /**
    * Add new design
    *
    * @param DesignDetails
    */
    createDesign(versionId: number, formData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/product/version/${versionId}/designs/`, formData);
    }

    /**
    * Update design
    *
    * @param DesignDetails
    */
    updateDesign(versionId: number, designId: number, formData): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/product/version/${versionId}/designs/${designId}/`, formData);
    }

    /**
    * Download design source
    */
    getSourceFile(url): any {
        return this._httpClient.get(url, {
            responseType: 'arraybuffer'
        });
    }

    //Version design management
    private _autofill: BehaviorSubject<AutofillData[] | null> = new BehaviorSubject(null);
    get autofillData$(): Observable<AutofillData[]> {
        return this._autofill.asObservable();
    }

    /**
    * Get autofill list
    */
    getAutofillDataList(versionId: number): Observable<AutofillData[]> {
        return this._httpClient.get<AutofillData[]>(`${BASE_URL}administration/product/version/${versionId}/autofill/`).pipe(
            tap((response: AutofillData[]) => {
                this._autofill.next(response ? response : []);
            })
        );
    }

    updateAutofillData(versionId: number): Observable<any> {
        let uploadData = this._autofill.value.map(data => {
            return { autofill: data.autofill, data_id: data.data_id, id: data.id }
        })

        return this._httpClient.put(`${BASE_URL}administration/product/version/${versionId}/autofill/`, uploadData);
    }

}
