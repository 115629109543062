import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AdminService } from 'app/core/admin/admin.service';
import { InitialData } from 'app/modules/admin/manage-admin/admin.types';

@Injectable({
    providedIn: 'root'
})
export class AdminDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _adminService: AdminService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._adminService.getAdminsInitial()
            .pipe(
                map((admins) => ({
                    admins
                })
                ),
                catchError(err => {
                    this._adminService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().admins);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    admins: any = [];
}