import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FAQService } from 'app/core/faq/faq.service';
import { InitialData } from './faq.types';

@Injectable({
    providedIn: 'root'
})
export class FaqDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _faqService: FAQService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(): Observable<InitialData> {
        return this._faqService.getCategories()
            .pipe(
                map((categories) => ({
                    categories
                })
                ),
                catchError(err => {
                    this._faqService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().categories);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    categories: any = [];
}