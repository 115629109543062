import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import {  Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FeedbackService } from 'app/core/feedback/feedback.service';
import { InitialData } from './feedback.types';

@Injectable({
    providedIn: 'root'
})
export class FeedbackDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _feedbackService: FeedbackService, private router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        // API call to get feedbacks llst
        return this._feedbackService.getFeedbacks()
            .pipe(
                map((feedbacks) => ({
                    feedbacks
                })
                ),
                catchError(err => {
                    this._feedbackService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().feedbacks);
                })
            );
    }

}

class DefaultResponse implements InitialData {
    feedbacks: any = [];
}