import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PromocodeService } from 'app/core/promocode/promocode.service';
import { InitialData } from 'app/modules/admin/manage-promocode/promocode.types';

@Injectable({
    providedIn: 'root'
})
export class PromocodeDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _promocodeService: PromocodeService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._promocodeService.getPromocodes()
            .pipe(
                map((promocodes) => ({
                    promocodes
                })
                ),
                catchError(err => {
                    this._promocodeService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().promo_codes);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    promo_codes: any = [];
}