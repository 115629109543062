import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Pagination } from 'app/shared/pagination.type';
import { Feedback } from './feedback.model';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ User Management
    // -----------------------------------------------------------------------------------------------------

    private _feedbacks: BehaviorSubject<Feedback[] | null> = new BehaviorSubject(null);
    errorMessage: string;

    get feedbacks$(): Observable<Feedback[]> {
        return this._feedbacks.asObservable();
    }

    /**
         * Get feedback list
         */
    getFeedbacks(): Observable<Feedback[]> {
        return this._httpClient.get<Feedback[]>(`${BASE_URL}administration/feedbacks/`).pipe(
            tap((response) => {
                this._feedbacks.next(response ? response : []);
            })
        );
    }

}
