import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { User, UserResponse } from 'app/core/user/user.types';
import { BASE_URL, MARKETING_URL, environment } from 'environments/environment';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Pagination } from 'app/shared/pagination.type';
import { PWANavigation } from 'app/shared/navigation';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _navigation: ReplaySubject<FuseNavigationItem[]> = new ReplaySubject<FuseNavigationItem[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private cookie: CookieService) {
        // let marketingNav = this.navigation.find(nav => { nav.id == 'marketing' })
        // marketingNav = {
        //     id: 'marketing',
        //     title: 'Marketing Dashboard', 
        //     function: (item: FuseNavigationItem) => { this.saveMarketingCookie() },
        //     type: 'basic',
        //     icon: 'heroicons_outline:newspaper',
        //     externalLink: true,
        //     link: MARKETING_URL
        // }

    }

    // saveMarketingCookie() {
    //     //    let cookie = new CookieService() 

    //     let DOMAIN = environment.production ? 'printingwarehouses.com' : 'printingw.xyz'
    //     this.cookie.set('marketing_token', this.authService.accessToken, { domain: DOMAIN, sameSite: 'None', expires: new Date(2030, 1, 1), path: '/', secure: true });
    //     this.cookie.set('token', this.authService.accessToken, { domain: DOMAIN, sameSite: 'None', expires: new Date(2030, 1, 1), path: '/', secure: true });

    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get profile
     */
    getUser(): Observable<any> {
        return this._httpClient.get<User>(`${BASE_URL}administration/user/`).pipe(
            switchMap((response: any) => {
                // Execute the observable
                // this._user.next(response);
                this.user = response;
                return of(response);
            })
        );
    }

    /**
     * Update self profile
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.put<User>(`${BASE_URL}administration/user/`, {
            first_name: user.first_name,
            middle_name: user.middle_name,
            last_name: user.last_name,
            phone_number: user.phone_number
        }).pipe(
            map((response) => {
                // Execute the observable
                this.user = response;
                return of(response);
            })
        );
    }

    /**
     * Update password
     *
     * @param user
     */
    updatePassword(user): Observable<any> {
        return this._httpClient.put<User>(`${BASE_URL}administration/user/`, { password: user.password });
    }

    /**
     * Update Profile pic
     *
     * @param formData with file "profile_image"
     */
    public updateProfilePicture(formData): Observable<any> {
        return this._httpClient.put<any>(`${BASE_URL}administration/user/`, formData).pipe(
            map((response) => {
                // Execute the observable
                this.user = response;
                return of(response);
            })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Navigation info
    // -----------------------------------------------------------------------------------------------------

    getNavigation(): Observable<any> {
        return new Observable(navigation => {
            setTimeout(() => {
                navigation.next(this.navigation);
            }, 1000);
        });
    }

    navigation = new PWANavigation(this.cookie).navigation;

    // -----------------------------------------------------------------------------------------------------
    // @ User Management
    // -----------------------------------------------------------------------------------------------------

    private _users: BehaviorSubject<User[] | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string;

    /**
     * Getter for users
     */
    get users$(): Observable<User[]> {
        return this._users.asObservable();
    }


    /**
     * Get users list
     */
    getUsersInitial(): Observable<UserResponse> {
        return this.getUsers(null, null, null, null, null);
    }

    /**
         * Get user list with search and sort
         */
    getUsers(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<UserResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<UserResponse>(`${BASE_URL}administration/users/`, {
            params: {
                page: page ? ++page : 1,
                sort: sort ? sort : '',
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._users.next(response.users ? response.users : []);
            })
        );
    }

    /**
     * Activate or suspend an user
     * @param isActive 'true' to activate 'false' to suspend
     * @param userId
     */
    changeUserStatus(isActive: boolean, userId: string): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/users/${userId}/`, {
            account_status: '' + (isActive ? 'active' : 'suspended')
        }).pipe(
            tap((res: any) => {
                let newArrayOfUsers = this._users.value;
                newArrayOfUsers.forEach(element => {
                    if (element.id == userId)
                        element.account_status = res.account_status ? res.account_status : element.account_status;
                });
                this._users.next(newArrayOfUsers);
                return of(res);
            }, err => of([]))
        )
    }
}
