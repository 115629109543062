import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProductService } from 'app/core/product/product.service';
import { InitialData } from './product-version.types';

@Injectable({
    providedIn: 'root'
})
export class ProductVersionDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _productService: ProductService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot): Observable<InitialData> {
        return this._productService.getVersionDetails(route.params['id'])
            .pipe(
                map((versionDetails) => ({
                    versionDetails
                })
                ),
                catchError(err => {
                    this._productService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().versionDetails);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    versionDetails: any = [];
}