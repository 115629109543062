import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { DashboardService } from 'app/core/dashboard/dashboard.service';
import { InitialData } from './dashboard.types';
import { map } from 'rxjs/operators';
import { Period } from 'app/core/dashboard/dashboard.model';
import { OrderService } from 'app/core/order/order.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _dashboardService: DashboardService, private _orderService : OrderService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return forkJoin([
            this._dashboardService.getOrderCategorySummary(Period.all),
            this._dashboardService.getOrderSummary(Period.all),
            this._dashboardService.getRevenueSummary(Period.all),
            this._orderService.getOrders(null, null,  null,  null, '','')
        ]).pipe(
            map(([orderCategorySummary, orderSummary, revenueSummary, orders]) => ({
                orderCategorySummary,
                orderSummary,
                revenueSummary,
                orders
                })
            )
        );
    }

}
