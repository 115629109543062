import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SubscriptionService } from 'app/core/subscription/subscription.service';

export const digitalGroupDataResolver = () => {
    const _subscriptionService = inject(SubscriptionService);

    return forkJoin([
        _subscriptionService.getDigitalPlanGroups(0, 100, null, null, null, null),
        _subscriptionService.getPlanCategories(),
    ]);
};