import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { AdditionalService, Category, DigitalPlan, DigitalPlanCategory, DigitalPlanGroup, DigitalPlanGroupResponse, DigitalPlanResponse, Feature, Interest, InterestResponse, Plan, Subscription, SubscriptionResponse } from './subscription.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    errorMessage: string = "Something went wrong ";

    constructor(private _httpClient: HttpClient) {
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Features management
    // -----------------------------------------------------------------------------------------------------
    private _features: BehaviorSubject<Feature[] | null> = new BehaviorSubject(null);

    get features$(): Observable<Feature[]> {
        return this._features.asObservable();
    }

    getFeatures(): Observable<Feature[]> {
        return this._httpClient.get<Feature[]>(`${BASE_URL}administration/subscription/features/`).pipe(
            tap((response) => {
                this._features.next(response);
            })
        );
    }

    deleteFeature(featureId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/subscription/feature/${featureId}/`).pipe(
            tap((res: any) => {
                this._features.next(this._features.value.filter(feature => feature.id !== featureId));
            }, err => of([]))
        )
    }

    createFeature(credentials: { title: string; }): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/subscription/features/`, credentials);
    }

    updateFeature(credentials: { title: string; }, featureId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/subscription/feature/${featureId}/`, credentials);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Category management
    // -----------------------------------------------------------------------------------------------------
    private _categories: BehaviorSubject<Category[] | null> = new BehaviorSubject(null);

    get categories$(): Observable<Category[]> {
        return this._categories.asObservable();
    }

    getCategories(): Observable<Category[]> {
        return this._httpClient.get<Category[]>(`${BASE_URL}administration/subscription/categories/`).pipe(
            tap((response) => {
                this._categories.next(response);
            })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Services management
    // -----------------------------------------------------------------------------------------------------
    private _services: BehaviorSubject<AdditionalService[] | null> = new BehaviorSubject(null);
    _categoryId: number;

    get services$(): Observable<AdditionalService[]> {
        return this._services.asObservable();
    }

    getServices(categoryId: number): Observable<Category> {
        return this._httpClient.get<Category>(`${BASE_URL}administration/subscription/category/${categoryId}/`).pipe(
            tap((response) => {
                this._services.next(response.additional_services);
                this._categoryId = response.id;
                if (response.additional_services.length == 0)
                    this.errorMessage = 'There are no services under this category';
            })
        );
    }

    deleteService(categoryId: number, serviceId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/subscription/category/${categoryId}/additional-services/${serviceId}/`).pipe(
            tap((res: any) => {
                this._services.next(this._services.value.filter(service => service.id !== serviceId));
            }, err => of([]))
        )
    }

    createService(categoryId: number, credentials: { title: string; }): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/subscription/category/${categoryId}/additional-services/`, credentials);
    }

    updateService(categoryId: number, serviceId: number, credentials: { title: string; }): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/subscription/category/${categoryId}/additional-services/${serviceId}/`, credentials);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Interest Management
    // -----------------------------------------------------------------------------------------------------

    private _interests: BehaviorSubject<Interest[] | null> = new BehaviorSubject(null);
    pagination: Pagination;

    /**
     * Getter for interests
     */
    get interests$(): Observable<Interest[]> {
        return this._interests.asObservable();
    }

    /**
     * Get interests list
     */
    getInterestsInitial(): Observable<InterestResponse> {
        return this.getInterests(null, null, null, null, null);
    }

    /**
    * Get interest list with search and sort
    */
    getInterests(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<InterestResponse> {
        let sortValue = sort;
        switch (sort) {
            case 'plan_category':
                sortValue = 'plan__category';
                break;
            case 'added_date':
                sortValue = 'added_at';
                break;
            case 'updated_date':
                sortValue = 'updated_at';
                break;
        }

        if (sortDirection == 'desc')
            sortValue = '-' + sortValue;
        return this._httpClient.get<InterestResponse>(`${BASE_URL}administration/subscription/interests/`, {
            params: {
                page: page ? ++page : 1,
                sort: sortValue ? sortValue : '',
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._interests.next(response.interests ? response.interests : []);
            })
        );
    }

    /**
     * Change status
     * @param newStatus
     */
    changeInterestStatus(interestId: number, interest: Interest): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/subscription/interest/${interestId}/`, {
            status: interest.status
        }).pipe(
            tap((res: any) => {
                let newArrayOfInterests = this._interests.value;
                newArrayOfInterests.forEach(element => {
                    if (element.id == interestId)
                        element.status = res.status ? res.status : element.status;
                });
                this._interests.next(newArrayOfInterests);
                return of(res);
            }, err => of([]))
        )
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Plan management
    // -----------------------------------------------------------------------------------------------------
    private _plans: BehaviorSubject<Plan[] | null> = new BehaviorSubject(null);

    get plans$(): Observable<Plan[]> {
        return this._plans.asObservable();
    }

    getPlans(): Observable<Plan[]> {
        return this._httpClient.get<Plan[]>(`${BASE_URL}administration/subscription/plans/`).pipe(
            tap((response) => {
                this._plans.next(response);
                if (response.length == 0)
                    this.errorMessage = 'There are no services under this category';
            })
        );
    }

    private _plan: BehaviorSubject<Plan | null> = new BehaviorSubject(null);

    get plan$(): Observable<Plan> {
        return this._plan.asObservable();
    }

    getPlan(planId: number): Observable<Plan> {
        return this._httpClient.get<Plan>(`${BASE_URL}administration/subscription/plan/${planId}/`).pipe(
            tap((response) => {
                this._plan.next(response);
            })
        );
    }

    deletePlan(planId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/subscription/plan/${planId}/`).pipe(
            tap((res: any) => {
                this._plans.next(this._plans.value.filter(plan => plan.id !== planId));
            }, err => of([]))
        )
    }

    createPlan(credentials: Plan): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/subscription/plan/`, credentials);
    }

    updatePlan(planId: number, credentials: Plan): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/subscription/plan/${planId}/`, credentials);
    }

    /**
    * Change featured status of plan
    *
    * @param PlanDetails
    */
    changeFeaturedStatus(planId: number, plan: Plan): Observable<any> {
        let tempPlan = this._plans.value.find(p => p.id == planId);
        if (plan.featured != null)
            tempPlan.featured = plan.featured
        this._plans.next(this._plans.value);
        return this._httpClient.put(`${BASE_URL}administration/subscription/plan/${planId}/`, plan).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                if (response?.id != null) {
                    //Change local values with latest values
                    let tempPlan = this._plans.value.find(p => p.id == planId);
                    tempPlan.featured = response.is_premium;
                    this._plans.next(this._plans.value);
                }
            }, err => {
                //Revert values
                let tempPlan = this._plans.value.find(p => p.id == planId);
                if (plan.featured != null)
                    tempPlan.featured = !plan.featured
                this._plans.next(this._plans.value);
            }));
    }

    /**
   * Change active status of plan
   *
   * @param PlanDetails
   */
    changeActiveStatus(planId: number, plan: Plan): Observable<any> {
        let tempPlan = this._plans.value.find(p => p.id == planId);
        if (plan.active != null)
            tempPlan.active = plan.active
        this._plans.next(this._plans.value);
        return this._httpClient.put(`${BASE_URL}administration/subscription/plan/${planId}/`, plan).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                if (response?.id != null) {
                    //Change local values with latest values
                    let tempPlan = this._plans.value.find(p => p.id == planId);
                    tempPlan.active = response.active;
                    this._plans.next(this._plans.value);
                }
            }, err => {
                //Revert values
                let tempPlan = this._plans.value.find(p => p.id == planId);
                if (plan.active != null)
                    tempPlan.active = !plan.active
                this._plans.next(this._plans.value);
            }));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Digital Subscription Management
    // -----------------------------------------------------------------------------------------------------

    private _digitalSubscriptions: BehaviorSubject<Subscription[] | null> = new BehaviorSubject(null);
    private _subscriptionDetails: BehaviorSubject<Subscription | null> = new BehaviorSubject(null);
    private _digitalCategories: BehaviorSubject<DigitalPlanCategory[] | null> = new BehaviorSubject(null);
    private _digitalGroups: BehaviorSubject<DigitalPlanGroup[] | null> = new BehaviorSubject(null);
    private _digitalPlans: BehaviorSubject<DigitalPlan[] | null> = new BehaviorSubject(null);
    private _digitalPlan: BehaviorSubject<DigitalPlan | null> = new BehaviorSubject(null);

    /**
     * Getter for digital subscriptions
     */
    get digitalSubscriptions$(): Observable<Subscription[]> {
        return this._digitalSubscriptions.asObservable();
    }

    get digitalCategories$(): Observable<DigitalPlanCategory[]> {
        return this._digitalCategories.asObservable();
    }

    get digitalPlanGroups$(): Observable<DigitalPlanGroup[]> {
        return this._digitalGroups.asObservable();
    }

    get digitalPlans$(): Observable<DigitalPlan[]> {
        return this._digitalPlans.asObservable();
    }

    get digitalPlan$(): Observable<DigitalPlan> {
        return this._digitalPlan.asObservable();
    }

    get digitalSubscriptionDetails$(): Observable<Subscription> {
        return this._subscriptionDetails.asObservable();
    }

    getDigitalSubscriptionsInitial(): Observable<SubscriptionResponse> {
        return this.getDigitalSubscriptions(null, null, null, null, null);
    }

    /**
    * Get digital subscription list with search and sort
    */
    getDigitalSubscriptions(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, category: string = null): Observable<SubscriptionResponse> {
        let sortValue = sort;

        if (sortDirection == 'desc')
            sortValue = '-' + sortValue;
        return this._httpClient.get<SubscriptionResponse>(`${BASE_URL}administration/digital-subscription/list/`, {
            params: {
                page: page ? ++page : 1,
                sort: sortValue ? sortValue : '',
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 10,
                category: (category == null || category == "null") ? null : category
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._digitalSubscriptions.next(response.digital_subscriptions ? response.digital_subscriptions : []);
            })
        );
    }

    /**
    * Get digital subscription details
    */
    getDigitalSubscriptionDetails(subscriptionId: number = 10): Observable<Subscription> {
        return this._httpClient.get<Subscription>(`${BASE_URL}administration/digital-subscription/${subscriptionId}/`,).pipe(
            tap((response) => {
                this._subscriptionDetails.next(response);
            })
        );
    }

    getPlanCategories() {
        return this._httpClient.get<DigitalPlanCategory[]>(`${BASE_URL}administration/digital_subscription_plan_categories/`,).pipe(
            tap({
                next: (response: any) => {
                    this._digitalCategories.next(response);
                }
            })
        );
    }

    getDigitalPlanGroups(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, category: string): Observable<DigitalPlanGroupResponse> {
        let sortValue = sort;

        if (sortDirection == 'desc')
            sortValue = '-' + sortValue;
        return this._httpClient.get<DigitalPlanGroupResponse>(`${BASE_URL}administration/digital_subscription_plan_group/`, {
            params: {
                page: page ? ++page : 1,
                sort: sortValue ? sortValue : '',
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 10,
                category: (category == null || category == "null") ? '' : category
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = "Something went wrong. Please try again."
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._digitalGroups.next(response.data ?? []);
            })
        );
    }

    deleteDigitalPlanGroups(planId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/digital_subscription_plan_group/${planId}/`).pipe(
            tap({
                next: (response: any) => {
                    this._digitalGroups.next(this._digitalGroups.value.filter(plan => plan.id !== planId));
                }
            })
        )
    }

    createDigitalPlanGroups(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/digital_subscription_plan_group/`, credentials);
    }

    updateDigitalPlanGroups(planId: number, credentials): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/digital_subscription_plan_group/${planId}/`, credentials);
    }


    getDigitalPlans(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, category: string): Observable<DigitalPlanResponse> {
        let sortValue = sort;

        if (sortDirection == 'desc')
            sortValue = '-' + sortValue;
        return this._httpClient.get<DigitalPlanResponse>(`${BASE_URL}administration/digital_subscription_plan/`, {
            params: {
                page: page ? ++page : 1,
                sort: sortValue ? sortValue : '',
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 10,
                category: (category == null || category == "null") ? '' : category
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = "Something went wrong. Please try again."
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._digitalPlans.next(response.data ?? []);
            })
        );
    }

    getDigitalPlanDetails(planId: number = 10): Observable<DigitalPlan> {
        return this._httpClient.get<DigitalPlan>(`${BASE_URL}administration/digital_subscription_plan/${planId}/`,).pipe(
            tap((response) => {
                this._digitalPlan.next(response);
            })
        );
    }

    deleteDigitalPlan(planId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/digital_subscription_plan/${planId}/`).pipe(
            tap({
                next: (res: any) => {
                    this._digitalPlans.next(this._digitalPlans.value.filter(plan => plan.id !== planId));
                }
            })
        )
    }

    createDigitalPlans(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/digital_subscription_plan/`, credentials);
    }

    updateDigitalPlan(planId: number, credentials): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/digital_subscription_plan/${planId}/`, credentials);
    }
}
