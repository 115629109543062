import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Pricing, PricingOption, PricingQuantity } from './pricing.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class PricingService {

    private _pricing: BehaviorSubject<PricingOption[] | null> = new BehaviorSubject(null);
    private _pricingQuantities: BehaviorSubject<PricingQuantity[] | null> = new BehaviorSubject(null);

    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for pricing
     */
    get pricing$(): Observable<PricingOption[]> {
        return this._pricing.asObservable();
    }

    /**
     * Getter for pricing quantities
     */
    get pricingQuantities$(): Observable<PricingQuantity[]> {
        return this._pricingQuantities.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get pricing list 
     */
    getPricing(productId: number): Observable<Pricing[]> {
        return this._httpClient.get<Pricing[]>(`${BASE_URL}administration/pricing/product/${productId}/`).pipe(
            tap((response) => {
                this.errorMessage = response ? "" : "No pricing added for this product";
                this._pricing.next(response.length > 0 ? response[0].options : []);
            })
        );
    }


    /**
    * Delete
    * @param optionId
    */
    deletePricingOption(optionId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/pricing/option/${optionId}/`).pipe(
            tap((res: any) => {
                this._pricing.next(this._pricing.value.filter(option => option.id !== optionId));
                return of(res);
            }, err => of([]))
        )
    }

    /**
    * Add new pricing option
    *
    * @param PricingDetails
    */
    createPricingOption(formData, productId: number): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/pricing/product/${productId}/`, formData);
    }

    /**
    * Update pricing option
    *
    * @param PricingDetails
    */
    updatePricingOption(formData, optionId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/pricing/option/${optionId}/`, formData);
    }

    ///////// Quantity

    /**
    * Get pricing quantities 
    */
    getQuantities(optionId: number): Observable<PricingQuantity[]> {
        return this._httpClient.get<PricingQuantity[]>(`${BASE_URL}administration/pricing/option/${optionId}/`).pipe(
            tap((response) => {
                this.errorMessage = response ? "" : "No pricing added for this product";
                this._pricingQuantities.next(response.length > 0 ? response : []);
            })
        );
    }

    /**
        * Delete
        * @param quantityId
        */
    deleteQuantity(quantityId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/pricing/${quantityId}/`).pipe(
            tap((res: any) => {
                this._pricingQuantities.next(this._pricingQuantities.value.filter(quantity => quantity.id !== quantityId));
                return of(res);
            }, err => of([]))
        )
    }

    /**
    * Add new quantity option
    *
    * @param PricingDetails
    */
    createQuantity(formData, optionId: number): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/pricing/option/${optionId}/`, formData);
    }

    /**
    * Update pricing quantity
    *
    * @param PricingDetails
    */
    updateQuantity(formData, quantityId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/pricing/${quantityId}/`, formData);
    }

}
