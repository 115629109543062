import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Keyword } from './keyword.model';

@Injectable({
    providedIn: 'root'
})
export class KeywordService {

    private _keywords: BehaviorSubject<Keyword[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for keywords
     */
    get keywords$(): Observable<Keyword[]> {
        return this._keywords.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get keyword list 
     */
    getKeywords(): Observable<Keyword[]> {
        return this._httpClient.get<Keyword[]>(`${BASE_URL}administration/keywords/`).pipe(
            tap((response) => {
                this._keywords.next(response);
            })
        );
    }

    /**
     * Delete Keyword
     * @param keywordId
     */
    deleteKeyword(keywordId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}administration/keyword/${keywordId}/`).pipe(
            tap((res: any) => {
                this._keywords.next(this._keywords.value.filter(keyword => keyword.id !== keywordId));
            }, err => of([]))
        )
    }

    createKeyword(credentials: { title: string; }): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/keyword/`, credentials);
    }

    updateKeyword(credentials: { title: string; }, keywordId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/keyword/${keywordId}/`, credentials);
    }

}
