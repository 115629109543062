import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Category, CategoryResponse, SubCategory, SubCategoryResponse } from './category.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    private _categories: BehaviorSubject<Category[] | null> = new BehaviorSubject(null);
    private _subCategories: BehaviorSubject<SubCategory[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for categories
     */
    get categories$(): Observable<Category[]> {
        return this._categories.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get category list 
     */
     getCategories(): Observable<CategoryResponse> {
        return this._httpClient.get<CategoryResponse>(`${BASE_URL}administration/categories/`).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this._categories.next(response.categories ? response.categories : []);
            })
        );
    }

    /**
     * Get category list 
     */
    getTemplateTypes(): Observable<any> {
        return this._httpClient.get<any>(`${BASE_URL}administration/categories/template_type/`)
    }

    /**
    * Create category
    *
    * @param Category 
    */
    createCategory(category: any): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/categories/manage/`, {
            title: category.title,
            // is_printing: category.is_printing,
            can_autofill: category.can_autofill,
            template_type: category.template_type,
            tag_name: category.tag_name
        });
    }

    /**
     * Delete category
     */
    deleteCategory(categoryId: number): Observable<any> {
        return this._httpClient.delete<any>(`${BASE_URL}administration/categories/manage/${categoryId}/`).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this._categories.next(this._categories.value.filter(category => category.id !== categoryId));
            })
        );
    }

    // /**
    // * Update category
    // * @param Category Id
    // * @param Category 
    // */
    updateCategory(categoryId: number, category): Observable<any> {
        //Temporary change values
        let cat = this._categories.value.find(category => category.id == categoryId);
        console.log(cat)
        if (category.can_autofill != null)
            cat.can_autofill = category.can_autofill
        if (category.is_printing != null)
            cat.is_printing = category.is_printing
        if (category.disabled != null)
            cat.disabled = category.disabled
        this._categories.next(this._categories.value);

        return this._httpClient.put(`${BASE_URL}administration/categories/manage/${categoryId}/`, category).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                if (response?.id != null) {
                    //Change local values with latest values
                    let cat = this._categories.value.find(category => category.id == categoryId);
                    cat.can_autofill = response.can_autofill;
                    cat.is_printing = response.is_printing;
                    cat.title = response.title;
                    cat.tag_name = response.tag_name;
                    cat.template_type = response.template_type;
                    cat.disabled = response.disabled;
                    cat.maximum_sides = response.maximum_sides;
                    cat.bullets = response.bullets
                    cat.sub_title = response.sub_title
                    cat.image = response.image
                    cat.description = response.description

                    this._categories.next(this._categories.value);
                }
            }, err => {
                //Revert values
                let cat = this._categories.value.find(category => category.id == categoryId);
                console.log(cat)
                if (category.can_autofill != null)
                    cat.can_autofill = !category.can_autofill
                if (category.is_printing != null)
                    cat.is_printing = !category.is_printing
                if (category.disabled != null)
                    cat.disabled = !category.disabled
                this._categories.next(this._categories.value);
            })
        );
    }

    //////////////////////////////////////////////////////////////////////////////////////////
    /**
     * Getter for subcategories
     */
    get subCategories$(): Observable<SubCategory[]> {
        return this._subCategories.asObservable();
    }

    /**
     * Get subcategory list 
     */
    getSubCategories(categoryId): Observable<SubCategoryResponse> {
        return this._httpClient.get<SubCategoryResponse>(`${BASE_URL}administration/categories/${categoryId}/`).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this._subCategories.next(response.sub_categories ? response.sub_categories : []);
            })
        );
    }

    /**
    * Create Subcategory
    *
    * @param Subcategory 
    */
    createSubCategory(categoryId: number, subCategory: any): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/categories/manage/${categoryId}/`, subCategory);
    }

    updateSubCategory(categoryId: number, subCategory): Observable<any> {
        //Temporary change values
        let cat = this._subCategories.value.find(category => category.id == categoryId);
        if (subCategory.disabled != null)
            cat.disabled = subCategory.disabled
        this._subCategories.next(this._subCategories.value);
        return this._httpClient.put(`${BASE_URL}administration/categories/manage/${categoryId}/`, subCategory).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                if (response?.id != null) {
                    let cat = this._subCategories.value.find(category => category.id == categoryId);
                    cat.title = response.title;
                    cat.maximum_sides = response.maximum_sides;
                    cat.disabled = response.disabled;
                    cat.image = response.image;
                    this._subCategories.next(this._subCategories.value);
                }
            }, err => {
                //Revert values
                let cat = this._subCategories.value.find(category => category.id == categoryId);
                if (subCategory.disabled != null)
                    cat.disabled = !subCategory.disabled
                this._subCategories.next(this._subCategories.value);
            })
        );
    }

    /**
     * Delete subcategory
     */
    deleteSubCategory(categoryId: number): Observable<any> {
        return this._httpClient.delete<any>(`${BASE_URL}administration/categories/manage/${categoryId}/`).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this._subCategories.next(this._subCategories.value.filter(category => category.id !== categoryId));
            })
        );
    }

}
