import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Admin, AdminResponse } from './admin.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    private _admins: BehaviorSubject<Admin[] | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for admins
     */
    get admins$(): Observable<Admin[]> {
        return this._admins.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get admin list
     */
    getAdminsInitial(): Observable<AdminResponse> {
        return this.getAdmins(null, null, null, null, null);
    }

    /**
     * Get admin list with search and sort
     */
    getAdmins(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<AdminResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<AdminResponse>(`${BASE_URL}administration/admins/`, {
            params: {
                page: page ? ++page : 1,
                sort: sort ? sort : '',
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._admins.next(response.users ? response.users : []);
            })
        );
    }

    /**
     * Activate or suspend an admin
     * @param isActive 'true' to activate 'false' to suspend
     * @param adminId
     */
    changeAdminStatus(isActive: boolean, adminId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/admins/${adminId}/`, {
            account_status: '' + (isActive ? 'active' : 'suspended')
        }).pipe(
            tap((res: any) => {
                let newArrayOfAdmins = this._admins.value;
                newArrayOfAdmins.forEach(element => {
                    if (element.id == adminId)
                        element.account_status = res.account_status ? res.account_status : element.account_status;
                });
                this._admins.next(newArrayOfAdmins);
                return of(res);
            }, err => of([]))
        )
    }

    /**
     * Change password
     * @param adminId
     * @param password
     */
    resetPassword(adminId: number, password: string): Observable<any> {
        return this._httpClient.put(`${BASE_URL}administration/admins/${adminId}/`, { password: password });
    }

    /**
    * Create admin
    *
    * @param AdminDetails
    */
    createAdmin(credentials: { name: string; email: string; phone_number: string; account_type: string }): Observable<any> {
        return this._httpClient.post(`${BASE_URL}administration/admins/`, credentials);
    }



}
